import React from 'react'
import EnLayout from '../components/en_layout'
import Header from '../components/header'
import SEO from '../components/seo'
import { Link } from "gatsby"
import ImagenPresentacion1 from '../components/imageComponents/imagenPresentacion1'
import ImagenPresentacion2 from '../components/imageComponents/imagenPresentacion2'
import ImagenDorados from '../components/imageComponents/imageindexDorado'
import EnSlideShow from '../components/imageComponents/en_slideShow'
import Estilos from '../components/mainContent/container.module.css'

const EnIndexPage = () => (
  <EnLayout>
    <SEO title='MAQUINAS DE TERMOIMPRESION - HOT FOIL STAMPING' />
    <Header siteTitle='HOT FOIL STAMPING' />
    <div className={Estilos.slideshow}>
      <div className='row justify-content-md-center' style={{ "marginTop": "1rem" }}>
        <EnSlideShow />
      </div>

      <div className='row justify-content-md-center'>
        <div className='col-12 col-md-8 conmargen'>
          <div className="conaire">
            {/* <p>
              <b> <span className="oro"> OR-PRINTER </span></b> es <b>fabricante</b> de máquinas de termoimpresión.
           </p> */}
            <p>We also provide all the necessary consumables such as
            <Link className="maquinas" to='/en_grabados/'> &nbsp;engravings, </Link>
              <Link className="maquinas" to='/en_peliculas/'> &nbsp;foils, </Link> &nbsp;etc.
          </p>
          </div>
          <div className='row'>
            <div className='col-12 col-md-4'>
              <ImagenDorados />
            </div>
            <div className='col-12 col-md-4'>
              <ImagenPresentacion1 />
            </div>
            <div className='col-12 col-md-4'>
              <ImagenPresentacion2 />
            </div>
          </div>
        </div>
      </div>


    </div>
  </EnLayout>
)

export default EnIndexPage
