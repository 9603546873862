import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import Estilos from '../../components/mainContent/container.module.css'

const EnSlideShow = () => {
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      if (index === 3) { // total number of images minus 1
        setIndex(0);
      } else {
        setIndex(prev => prev + 1);
      }
    }, 3000); //duration
    return () => clearInterval(timer); //cleanup
  }, [index]); //compare

  // filter by sub-directory name slideshow
  const allImagesQuery = graphql`
    query {
        allFile(filter: {relativeDirectory: {eq: "slideShow"}, 
            extension: {regex: "/(jpg)|(png)|(jpeg)/"}}) {
        totalCount
        edges {
            node {
            base
            childImageSharp {
              fixed(
                width:1500, height:500,quality: 100,
              ){
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
              fluid(maxWidth: 1500, pngQuality: 100){
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
            }
        }
        }
    }
  `
  const {
    allFile: { edges: images }, //destructuring
  } = useStaticQuery(allImagesQuery)

  return (
    <>
      <BackgroundImage
        className={Estilos.masthead}
        // style={{width:"30%", height:"30"}}
        fluid={images[index].node.childImageSharp.fluid}
        // alt={images[index].node.base.split(".")[0]}
        fadeIn={false}
        backgroundColor={"#1d1d1d"} style={{ "backgroundColor": "#1d1d1d" }}
      >
        <div className={Estilos.blackoverlay}>
          <div className='col-12 col-md-12 nopadding ' >
            <h2 className="oroligth"> PRINT WITHOUT INK!</h2>
            <h3>Clean, easy, fast, without residue or odor.</h3>
            <p>With our machines, you can print <b> WITHOUT INK </b>
            on all types of materials: paper, cardboard, leather, wood and
            all kinds of plastics.
          </p>
          <p>
              <b> <span className="oroligth"> OR-PRINTER </span></b> is a thermal printing machines <b> manufacturer </b> .
           </p>
          </div>
        </div>
      </BackgroundImage>
    </>
  )
}

export default EnSlideShow